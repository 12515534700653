<div class="kma picture white_arrow" [ngClass]="sectionClass">
  <ng-content></ng-content>
  <div class="kma_picture_wrapper" [ngClass]="{'black_tint': blackTint}" *ngIf="landingBgImage">
    <picture *ngIf="autoplayBgImage || threesixtyVideoImage; else otherTemplate">
      <source media="(min-width: 768px)"
        srcset="{{ staticUrlForImgNew + landingBannerData?.PlaceholderImageForVideoUploaded?.image?.contentUrl }}">
      <source media="(max-width: 767px)"
        *ngIf="landingBannerData?.PlaceholderImageForMobileVideoUploaded?.image?.contentUrl"
        srcset="{{ staticUrlForImgNew + landingBannerData?.PlaceholderImageForMobileVideoUploaded?.image?.contentUrl }}">
      <img src="{{ staticUrlForImgNew + landingBannerData?.PlaceholderImageForVideoUploaded?.image?.contentUrl }}"
        class="img-fluid kmaimage" [alt]="landingBannerData?.ImageAltText.data">
    </picture>
    <ng-template #otherTemplate>
      <picture>
        <source media="(min-width: 768px)"
          srcset="{{ staticUrlForImgNew + landingBannerData?.UploadImageVideo?.document?.contentUrl }}">
        <source media="(max-width: 767px)" *ngIf="landingBannerData?.UploadMobileKMAImageVideo?.document?.contentUrl"
          srcset="{{ staticUrlForImgNew + landingBannerData?.UploadMobileKMAImageVideo?.document?.contentUrl }}">
        <img src="{{ staticUrlForImgNew + landingBannerData?.UploadImageVideo?.document?.contentUrl }}"
          class="img-fluid" [ngClass]="pageClass" [alt]="landingBannerData?.ImageAltText?.data">
      </picture>
    </ng-template>
  </div>
  <div class="kma_video_wrapper" *ngIf="!landingBgImage || autoplayBgImage">
    <embed src="./assets/videos/blank.mp3" allow="autoplay" class="d-none">
    <video muted loop playsInline controlsList="nodownload" class="kma_video_wrapper--video"
      poster="{{staticUrlForImgNew + videoPlaceholderSrc}}" #video (canplay)="video.play()"
      (loadedmetadata)="video.muted = true">
      <source src="{{staticUrlForImgNew + videoSrc}}" type="video/mp4">
      Your browser does not support HTML5 video.
    </video>
  </div>
  <!-- [ngClass]="{'brandon_grotesquelight': bannerPage == 'tcc'}" -->
  <div class="kma_text_wrapper">
    <div data-aos="fade-up">
      <h1 [innerHTML]="landingBannerData?.Headline?.data"></h1>
      <p *ngIf="landingBannerData?.SubTitle?.data" [innerHTML]="landingBannerData?.SubTitle?.data"></p>
      <!-- <a href="javascript:void(0)" *ngIf="autoplayBgImage" (click)="_sharedService.checkCTALink($event)"
        class="play_href" [ngClass]="anchorClass.get(bannerPage)">
        <img src="./assets/images/playbutton.svg" class="img-fluid play_btn" alt="play button">
      </a> -->
      <ng-container *ngIf="bannerPage=='landing' || bannerPage == 'cec'">
        <a *ngIf="landingBannerData?.FirstHideCTA?.data =='false'" [href]="landingBannerData?.FirstCTALink?.data" class="cta-prim color-gold dark mr-3 banner-btn" (click)="callBannerCTEvent('Section_Primary_Links', landingBannerData?.FirstCTAText?.data); _sharedService.checkCTALink($event, landingBannerData?.FirstCTALink?.data);"><span class="cta-prim-txt">{{landingBannerData?.FirstCTAText?.data}}</span></a>
        <a *ngIf="landingBannerData?.SecondHideCTA?.data =='false'" [href]="landingBannerData?.SecondCTALink?.data" class="watchVideoClick cta-prim color-gold dark banner-btn" data-target="#video_box" [attr.data-url]="staticUrlForImgNew + landingBannerData?.SecondCTALink?.data"
        data-toggle="modal" (click)="callBannerCTEvent('Media_Links', landingBannerData?.SecondCTAText?.data)"><span class="cta-prim-txt">{{landingBannerData?.SecondCTAText?.data}}</span></a>
      </ng-container>
      <a *ngIf="threesixtyVideo" href="javascript:void(0)" class=" play360"
        [attr.videosrc]="staticUrlForImgNew + threesixtySrc">
        <div class="jwc_360tour_btn--icon">
          <img src="./assets/images/360/360.svg" class="img-fluid" alt="play360">
        </div>
      </a>
      <a *ngIf="threesixtyImage" href="javascript:void(0)" class=" play360"
        [attr.videosrc]="staticUrlForImgNew + threesixtySrc">
        <div class="jwc_360tour_btn--icon">
          <img src="./assets/images/360/360.svg" class="img-fluid" alt="play360">
        </div>
      </a>
    </div>
  </div>

  <div class="kma_arrow" *ngIf="bannerPage !== 'das'">
    <a href="javascript:void(0)" routerLink="./" [fragment]="(fragmentId)?fragmentId: 'section_2'"
      class="kma_arrow_link">
      <span class="icon-rh-arrow"></span>
    </a>
  </div>

  <!-- <div class="kma_notification" *ngIf='bannerPage == "nmaac"'>
    <div class="container">
      <div class="row">
        <div class="col-md-5">
          <h3 class="kma_notification--title">The Lion King - Musical</h3>
          <p class="kma_notification--text">The iconic African beats have found their way to Mumbai. At last. Bow to the Lion King.</p>
        </div>
        <div class="col-md-3">
          <p class="kma_notification--date">24th April - 29th April 2019</p>
          <p class="kma_notification--time">6:00 PM - 9:00 PM</p>
        </div>
        <div class="col-md-4">
          <a (click)="_sharedService.checkCTALink($event)" class="cta-prim icon icon-ticket">
            <span class="cta-prim-txt">BUY TICKETS</span>
          </a>
          <a (click)="_sharedService.checkCTALink($event)" class="cta-sec">
            <span class="cta-sec-txt">PLAN YOUR VISIT</span>
          </a>
        </div>
      </div>
    </div>
  </div> -->

</div>
<app-video-popup></app-video-popup>