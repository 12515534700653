import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  landingBgImage: boolean;
  @Input() landingBannerData;
  @Input() bannerPage;
  @Input() fragmentId?;
  @Input() sectionClass?;
  @Input() blackTint?;
  @Input() autoplay?
  anchorClass;
  mainClass;
  pageName;
  screenClass;
  videoSrc;
  videoPlaceholderSrc;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  autoplayBgImage: boolean = false
  threesixtyVideoImage: boolean = false;
  threesixtySrc: any;
  threesixtyVideo: boolean = false;
  threesixtyImage: boolean = false;

  constructor(public _sharedService: SharedService, private activatedRoute: ActivatedRoute,
              private router: Router) { 
    this.pageName = this.activatedRoute.snapshot.data['page'];
    this.setPageClass(this.pageName);
    // [ngClass]="colorClass.get(colorList.primaryColor)"
    this.anchorClass = new Map([
    ["landing", "default"],
      ["cec", "blue"],
      ["nmaac", "pink"],
      ["garden", "green"],
      ["das", "default"]
    ]);
    this.mainClass = new Map([
      ["landing", ""],
      ["cec", ""],
      ["nmaac", ""],
      ["garden", ""],
    ]);
  }

  public pageClass: string = '';

setPageClass(pageName: string): void {
  if (pageName === 'cec') {
    this.pageClass = 'kmaimage'; // Apply this CSS class
  } else {
    this.pageClass = 'kma_picture_wrapper--img'; // Apply this CSS class for other pages
  }
}

  ngOnInit() {
    this._sharedService.load360Function();
    let isAutoplay = this.autoplay === false ? false : true
    if (this.landingBannerData?.BackgroundImageVideo?.data === 'image' ) {
      this.landingBgImage = true;
    } else if(this.landingBannerData?.BackgroundImageVideo?.data === '360 video' || this.landingBannerData?.BackgroundImageVideo?.data === '360 image'){
      this.landingBgImage = true;
      this.threesixtyVideoImage = true;
      if(this.landingBannerData?.BackgroundImageVideo?.data === '360 video') {
        this.threesixtyVideo = true
      } else{
        this.threesixtyImage = true
      }
      this.threesixtySrc = (window.innerWidth > 768) ? this.landingBannerData?.UploadImageVideo?.document?.contentUrl : this.landingBannerData?.UploadMobileKMAImageVideo?.document?.contentUrl;
    } else {
      if(!isAutoplay) {
        this.landingBgImage = true;
        this.autoplayBgImage = true;
      } else {       
        this.sectionClass = this.sectionClass + ' autoplay autoplay_text';
      }      
      this.videoSrc = (window.innerWidth > 768) ? this.landingBannerData?.UploadImageVideo?.document?.contentUrl : this.landingBannerData?.UploadMobileKMAImageVideo?.document?.contentUrl;
      this.videoPlaceholderSrc = (window.innerWidth > 768) ? this.landingBannerData?.PlaceholderImageForVideoUploaded?.image?.contentUrl : this.landingBannerData.PlaceholderImageForMobileVideoUploaded?.image?.contentUrl;
    }
  }
  
  callBannerCTEvent(eveName, linkClicked) {
    this._sharedService.callCTEvent(eveName, 'Banner', '', linkClicked);
  } 

}
