import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from '../../../environments/environment';
declare var jQuery: any;

@Component({
  selector: 'app-newsroom',
  templateUrl: './newsroom-new.component.html',
  styleUrls: ['./newsroom.component.css']
})
export class NewsroomComponent implements OnInit {
  subscription;
  newsroomColorList = {
    headerDataHoverColor: "#BA3F96", dataGetintouchTitleColor: '#4B4847', dataGetintouchLineColor: '#A67D54', dataPlanBgColor: '#FFFFFF'
  }
  newsroomKeyArray = [
    174555, //Press Images
    'NEWSROOM-PRESS-RELEASES',
    174536, // Press Kit
    'NEWSROOM-ARCHIVE',
    'NEWSROOM-ENQUIRE-NOW',
    'NEWSROOM-HERO-BANNER',
    'NEWSROOM-IN-THE-NEWS'
  ];
  brandList = [{ 'key': 'Brand', 'id': 'all' }, { 'key': 'Jio World Centre', 'id': 'jwc' }, { 'key': 'Jio World Convention Centre', 'id': 'cec' }, { 'key': 'Jio World Garden', 'id': 'jwg' }, { 'key': 'Nita Mukesh Ambani Cultural Centre', 'id': 'tcc' }, { 'key': 'Dhirubhai Ambani Square', 'id': 'das' }]
  newsroomPressKitData: any;
  newsroomPressReleasesData: any;
  // newsroomSubHeaderList = ["Press Releases", "In The News", "Press Kit", "Archive", "Enquire Now"];
  newsroomPressImagesData: any;
  newsroomHeroBanner: any
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  filteredPressReleaseData: any = [];
  newsroomEnquiryData: any;
  newsroomArchiveData: any;
  pdfName: any;
  newsroomPressKitDownloadDocs = [];

  filteredPressKitData = [];
  orgFilteredPressKitData = [];
  selectedAssetBrand = 'all';
  active: boolean = true;
  flag: boolean = true;
  isAllSelected: boolean = true;
  isLogoSelected: boolean = true;
  isColorSelected: boolean = true;
  isImgSelected: boolean = true;
  isVidSelected: boolean = true;
  insideflag: boolean = false;
  filteredPressImagesData: any = [];

  activeTab: any = 'pr';
  selectedBrand: any = 'all';
  activeData: any;
  searchModel;
  max: any = 5;
  pimax: any = 3;
  hideLoadMoreCTA: boolean = false;
  hidePiLoadMoreCTA: boolean = false;
  newsroomInTheNews: any;
  constructor(public _sharedService: SharedService) { }

  ngOnInit() {
    this.getNewsroomPageContent();
    // this.getNewsroomFragmentId();
  }
  getNewsroomPageContent() {
    this.subscription = this._sharedService.getCommonService().getlandingUrl(this.newsroomKeyArray).subscribe(
      (data: any) => {
        this.newsroomPressImagesData = data[0] ? data[0].data : data[0];
        this.newsroomPressReleasesData = data[1] ? data[1].data : data[1];
        this.newsroomPressKitData = data[2] ? data[2].data : data[2];
        this.newsroomArchiveData = data[3] ? data[3].data : data[3];
        this.newsroomEnquiryData = data[4] ? data[4].data : data[4];
        this.newsroomHeroBanner = data[5] ? data[5].data : data[5];
        this.newsroomInTheNews = data[6] ? data[6].data : data[6];
        this._sharedService.initializeCustomJs();
        this._sharedService.callCustomDropdown();
        this._sharedService.calldropdownActiveClass();
        this._sharedService.callPressReleaseKitClick();
        this.newsroomPressReleasesData.cards.forEach(element => {
          if (element) {
            this.filteredPressReleaseData.push(element);
          }
        });
        this.newsroomPressImagesData.cards.forEach(element => {
          if (element) {
            this.filteredPressImagesData.push(element);
          }
        });
        this.newsroomPressKitData.cards.forEach(element => {
          if (element) {
            this.newsroomPressKitDownloadDocs.push(element.PressImageCTALink.data);
          }
        });
        this.selectedBox('all', true, true);
        this.activeData = this.newsroomPressReleasesData
        this.sortBy('new')
        if (this.max >= this.filteredPressReleaseData.length) this.hideLoadMoreCTA = true
        if (this.pimax >= this.filteredPressImagesData.length) this.hidePiLoadMoreCTA = true
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }
  getNewsroomFragmentId(item) {
    return item.replace(/\s/g, '-').toLowerCase();
  }
  getTabName(tab) {
    this.activeTab = tab
    this.activeData = this.activeTab === 'pr' ? this.newsroomPressReleasesData : this.newsroomPressImagesData
    jQuery(".sort-dropdown").text('Sort By');
    this.sortBy('new')
    this.selectedBrand = this.selectedBrand ? this.selectedBrand : 'all'
    this.loadFilteredData()
  }
  getBrandName(brand) {
    this.selectedBrand = brand ? brand : 'all';
    jQuery(".sort-dropdown").text('Sort By');
    this.sortBy('new')
    this.loadFilteredData()
  }
  loadFilteredData() {
    this.activeTab === 'pr' ? this.filteredPressReleaseData = this.getFilteredData(this.activeData, this.selectedBrand) : this.filteredPressImagesData = this.getFilteredData(this.activeData, this.selectedBrand)
  }
  getFilteredData(data, brand) {
    return data.cards.filter(element => {
      if (element) {
        if (brand === 'all') {
          return element
        }
        else {
          return element.Brand.data.includes(brand);
        }
      }
    });
  }
  loadMoreClicked() {
    this.max = this.max + 5;
    if (this.max >= this.filteredPressReleaseData.length) this.hideLoadMoreCTA = true
  }
  loadMorePiClicked() {
    this.pimax = this.pimax + 3;
    if (this.pimax >= this.filteredPressImagesData.length) this.hidePiLoadMoreCTA = true
  }
  sortBy(type) {
    this.activeData.cards.sort((a, b) => {
      if (type == 'new') {
        return new Date(b.ReleaseDate.data).getTime() - new Date(a.ReleaseDate.data).getTime();
      } else if (type == 'old') {
        return new Date(a.ReleaseDate.data).getTime() - new Date(b.ReleaseDate.data).getTime();
      }
    })
    this.activeTab === 'pr' ? this.filteredPressReleaseData = this.activeData.cards : this.filteredPressImagesData = this.activeData.cards
  }
  callNewsroomCTEvent(btn, sectionTitle, pdfName?, staticLink?) {
    switch (btn) {
      // case 'pri':
      //   this._sharedService.callCTEvent('Section_Primary_Links', sectionTitle, '', staticLink);
      //   break;
      case 'sec':
        this._sharedService.callCTEvent('Section_Secondary_Links', sectionTitle);
        break;
      case 'download':
        this.pdfName = this._sharedService.getPdfName(pdfName);
        this._sharedService.callCTEvent('Download_Links', sectionTitle, this.pdfName);
        break;
    }
  }

  getFilteredAssets(brand) {
    this.selectedAssetBrand = brand;
    this.filteredPressKitData = this.orgFilteredPressKitData.filter(element => {
      if (element) {
        if (brand === 'all') {
          return element
        }
        else {
          return element.PressImagesBrand.data.includes(brand);
        }
      }
    });
  }

  selectedBox(val, flag, checked) {
    // this.filteredPressKitData = [];
    let item
    let removeIndex
    if (val == 'all') {
      if (checked) {
        this.isAllSelected = true;
        this.isLogoSelected = true;
        this.isColorSelected = true;
        this.isImgSelected = true;
        this.isVidSelected = true;
        // if (this.insideflag) {
        //   this.isLogoSelected = true;
        //   this.isColorSelected = true;
        //   this.isImgSelected = true;
        //   this.isVidSelected = true;
        //   this.insideflag = false;
        // } else {
        //   this.isLogoSelected = false;
        //   this.isColorSelected = false;
        //   this.isImgSelected = false;
        //   this.isVidSelected = false;
        //   this.insideflag = true;
        // }
        if (this.isLogoSelected && this.isColorSelected && this.isImgSelected && this.isVidSelected) {
          this.newsroomPressKitData.cards.forEach(element => {
            this.filteredPressKitData.push(element);
          });
        } else {
          this.filteredPressKitData = [];
        }
      } else {
        this.isLogoSelected = false;
        this.isColorSelected = false;
        this.isImgSelected = false;
        this.isVidSelected = false;
        this.isAllSelected = false;
        this.filteredPressKitData = [];
        // this.newsroomPressKitData.cards.forEach(element => {
        //   this.filteredPressKitData.push(element)
        // });
      }

    } else {
      // if (checked) {
        if (val == 'logo') {
          if (this.isLogoSelected) {
            item = this.newsroomPressKitData.cards.filter(element => element.PressImageMainHeader.data.toLowerCase() == val);
            item.forEach(ele => {
              if (this.filteredPressKitData.indexOf(ele) == -1) this.filteredPressKitData.push(ele)
            });

          } else {
            removeIndex = this.filteredPressKitData.findIndex(itm => itm.PressImageMainHeader.data.toLowerCase() === val);
            if (removeIndex !== -1) this.filteredPressKitData.splice(removeIndex, 1);
          }
        } else if (val == 'color') {
          if (this.isColorSelected) {
            item = this.newsroomPressKitData.cards.filter(element => element.PressImageMainHeader.data.toLowerCase() == val);
            item.forEach(ele => {
              if (this.filteredPressKitData.indexOf(ele) == -1) this.filteredPressKitData.push(ele)
            });
          } else {
            removeIndex = this.filteredPressKitData.findIndex(itm => itm.PressImageMainHeader.data.toLowerCase() === val);
            if (removeIndex !== -1) this.filteredPressKitData.splice(removeIndex, 1);
          }
        } else if (val == 'image') {
          if (this.isImgSelected) {
            item = this.newsroomPressKitData.cards.filter(element => element.PressImageMainHeader.data.toLowerCase() == val);
            item.forEach(ele => {
              if (this.filteredPressKitData.indexOf(ele) == -1) this.filteredPressKitData.push(ele)
            });
          } else {
            removeIndex = this.filteredPressKitData.findIndex(itm => itm.PressImageMainHeader.data.toLowerCase() === val);
            if (removeIndex !== -1) this.filteredPressKitData.splice(removeIndex, 1);
          }
        } else if (val == 'video') {
          if (this.isVidSelected) {
            item = this.newsroomPressKitData.cards.filter(element => element.PressImageMainHeader.data.toLowerCase() == val);
            item.forEach(ele => {
              if (this.filteredPressKitData.indexOf(ele) == -1) this.filteredPressKitData.push(ele)
            });
          } else {
            removeIndex = this.filteredPressKitData.findIndex(itm => itm.PressImageMainHeader.data.toLowerCase() === val);
            if (removeIndex !== -1) this.filteredPressKitData.splice(removeIndex, 1);
          }
        }
     // }
    }
    // console.log("filteredPressKitData: ", this.filteredPressKitData)
    this.orgFilteredPressKitData = [...this.filteredPressKitData];
    this.filteredPressKitData = this.orgFilteredPressKitData.filter(element => {
      if (element) {
        if (this.selectedAssetBrand === 'all') {
          return element
        }
        else {
          return element.PressImagesBrand.data.includes(this.selectedAssetBrand);
        }
      }
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  downloadFile(btn, sectionTitle, pdfValue?, linkClicked?) {
    switch (btn) {
      case 'pri':
        this._sharedService.callCTEvent('Section_Primary_Links', sectionTitle, '', linkClicked);
        break;
      case 'download':
        this.pdfName = this._sharedService.getPdfName(pdfValue);
        this._sharedService.callCTEvent('Download_Links', sectionTitle, this.pdfName);
        break;
      case 'sticky':
        this._sharedService.callCTEvent('Sticky_Menu_Links', '', '', linkClicked);
    }
  }

  //Certificate hard code data
  certificatedata:any=[
    {
      "MainHeader": {
        "data": "Environmental Clearance Copy (EC)"
      },
      "MediaKitCTALink": {
        "data": environment['certificateURL'][0]
      },
      MediaKitCTAText:{data: "READ MORE"}
    },
    {
      "MainHeader": {
        "data": "Consent to Establish (CTE)"
      },
      "MediaKitCTALink": {
        "data": environment['certificateURL'][1]
      },
      MediaKitCTAText:{data: "READ MORE"}
    },
        {
      "MainHeader": {
        "data": "Consent to Operate (CTO)"
      },
      "MediaKitCTALink": {
        "data": environment['certificateURL'][2]
      },
      MediaKitCTAText:{data: "READ MORE"}
    },

    {
      "MainHeader": {
        "data": "Environmental Statement"
      },
      "MediaKitCTALink": {
        "data": environment['certificateURL'][3]
      },
      MediaKitCTAText:{data: "READ MORE"}
    },
    {
      "MainHeader": {
        "data": "Latest Six Monthly Compliance Report"
      },
      "MediaKitCTALink": {
        "data": environment['certificateURL'][4]
      },
      MediaKitCTAText:{data: "READ MORE"}
    },
    {
      "MainHeader": {
        "data": "Public Notice About EC"
      },
      "MediaKitCTALink": {
        "data": environment['certificateURL'][5]
      },
      MediaKitCTAText:{data: "READ MORE"}
    }
  ]
  downloadFileCerticate(action: string, type: string, link: string, name: string) {
    if (action === 'download') {
      window.open(link, '_blank');
    }
  }

}
